import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import './styles/styles.css';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth/msalConfig';
import Config from './types/Config';

// TODO: Move to keyvault (or other secure place) once keyvault is ready
LicenseInfo.setLicenseKey(
  '09b0f2cf706e42255e7ce41a2c2a2917T1JERVI6NjA4ODcsRVhQSVJZPTE3MDk0NTgyNjIyOTQsS0VZVkVSU0lPTj0x'
);

const config = {
  apiEndpointUrl: process.env.REACT_APP_API_ENDPOINT_URL,
  ecgApiEndpointUrl: process.env.REACT_APP_ECG_API_ENDPOINT_URL,
  NODE_ENV: process.env.REACT_APP_NODE_ENV,
};

const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize().then(() => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Config.Provider value={config}>
          <App config={config} />
        </Config.Provider>
      </MsalProvider>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
