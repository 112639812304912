import { useState } from 'react';
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import AdminSettings from './AdminSettings';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OrganizationSearch from './OrganizationSearch';
import { useTheme } from '@mui/material';

import { ORGANIZATIONS_LIST } from '../../data/queries/setting';

export default function AdminMain() {
  const [activeOrg, setActiveOrg] = useState(null);
  const theme = useTheme();

  const {
    loading: orgListLoading,
    error: orgListError,
    data: orgListData,
  } = useQuery(ORGANIZATIONS_LIST);
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = newValue => {
    setSearchTerm(newValue);
  };

  if (orgListLoading) return <div>Loading Organizations...</div>;
  if (orgListError) return <div>{orgListError.message}</div>;

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flex: 1 }}>
        <OrganizationSearch
          handleSearchChange={handleSearchChange}
          search={searchTerm}
        />
        <List
          className='org-list'
          sx={{
            padding: '0 15px',
            overflowY: 'scroll',
            maxHeight: 'calc(100vh - 138px)',
          }}
        >
          {orgListData.organizations
            .filter(org =>
              org.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map(org => (
              <ListItemButton
                key={org.id}
                onClick={() => setActiveOrg(org)}
                selected={activeOrg?.id === org.id}
                sx={{
                  '&:hover': {
                    backgroundColor: theme.palette.primary.light,
                  },
                }}
              >
                <ListItemIcon>
                  <CheckCircleIcon color='primary' />
                </ListItemIcon>
                <ListItemText primary={org.name} />
              </ListItemButton>
            ))}
        </List>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 3,
          height: '100%',
        }}
      >
        <AdminSettings
          orgId={activeOrg?.id || null}
          orgName={activeOrg?.name || null}
        />
      </Box>
    </Box>
  );
}
